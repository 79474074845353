import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import NewArtistBanner from '../components/NewArtistBanner'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'

const Template = ({ children, ...props }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <NewArtistBanner />
          <div
            className={`siteRoot${
              props.pageClass ? ` ${props.pageClass}` : ''
            }`}
          >
            <Helmet>
              <title>{config.siteTitle}</title>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <link rel="icon" href={favicon} />
              <link
                href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Playfair+Display:700,700i"
                rel="stylesheet"
              />
              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
              />
            </Helmet>

            <>
              <Menu noHeroSection={props.noHeroSection} />
              {children}
              <Footer />
            </>
          </div>
        </>
      </ThemeProvider>
      <GlobalStyle />
    </>
  )
}

export default Template
