import React from 'react'
import styled from 'styled-components'
import Arrow from '../images/arrow--right.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import config from '../utils/siteConfig'

const Wrapper = styled.footer`
  margin-top: 0;
  padding: 40px ${props => props.theme.spacing.siteXPadding};
  background-color: white;
  color: ${props => props.theme.colors.darkText};
  text-align: center;
  border-top: 1px solid ${props => props.theme.colors.lightBorder};
`

const Title = styled.p`
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    /* margin-bottom: 30px; */
  }
`

const Form = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;

  input[type='email'] {
    flex: 1 1 auto;
    min-width: 0;
    padding-right: 50px; // button width + x padding
  }

  button[type='submit'] {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 15px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex-wrap: nowrap;

    button {
      width: initial;
      margin-top: 0;
    }
  }
`

const SocialLinks = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  li {
    a {
      padding: 5px 10px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 30px;
  }
`

const Copyright = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.grayTextAlt};

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-top: -20px;
    margin-bottom: 35px;
  }
`

const Footer = () => (
  <Wrapper>
    <Title className="section-title">Subscribe</Title>
    <Form>
      <input type="email" placeholder="Email Address" />
      <button type="submit" className="btn--clear">
        <Arrow width="20px" />
      </button>
    </Form>
    <SocialLinks>
      <li className="social-link">
        <a
          href="https://www.facebook.com/groups/1829586323964956/"
          target="_blank"
        >
          <Facebook width="9px" />
        </a>
      </li>
      <li className="social-link">
        <a href="https://www.instagram.com/maskcarabeauty/" target="_blank">
          <Instagram width="18px" />
        </a>
      </li>
    </SocialLinks>
    <Copyright>
      {config.copyright} {config.siteTitleAlt}
    </Copyright>
  </Wrapper>
)

export default Footer
