module.exports = {
  siteTitle: 'Maskcara Artist HQ',
  siteTitleAlt: 'Maskcara Artist Headquarters', // This allows an alternative site title for SEO schema.
  publisher: 'Maskcara', // Organization name used for SEO schema
  siteDescription: 'Resources, news, events, and updates for Maskcara artists.',
  siteUrl: 'https://www.maskcarahq.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomepage: 3, // Number of posts shown on the 1st page of of the index.js template (homepage)
  eventsPerHomepage: 4, // Number of events shown on the 1st page of of the index.js template (homepage)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: 'Maskcara', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.instagram.com/maskcarabeauty/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@twitter', // Change for Twitter Cards
  shortTitle: 'Artist HQ', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: `Copyright © ${new Date().getFullYear()}`, // Copyright string for the RSS feed
}
