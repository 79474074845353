import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'
import didoFont from '../../static/fonts/mc-didonesque-roman.woff2'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  @font-face{
    font-family: 'Didonesque';
    src: ${`url(${didoFont}) format('woff2')`};
  }

  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* Push site footer to bottom when page content doesn't fill the viewport */
  html, body {
    height: 100%;
  }

  .siteRoot {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    > * {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }

    > header,
    > footer {
      width: 100%;
      flex-grow: 0;
    }
  }
  /* End styles for sticking site footer to the bottom */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  /* Fix for jumpy page transitions…? See https://gist.github.com/javierlopezdeancos/1331b868f20f064afe7c */
  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }
  /* End styles for jumpy page fix */

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    width: 100%;
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    font-family: ${theme.typography.family};
    color: ${theme.colors.darkText};

    @media (-o-min-device-pixel-ratio: 13/10), (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 120dpi) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }

  body,
  .siteRoot {
    background-color: white;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.typography.displayFamily};
    font-weight: ${theme.typography.displayFwSm};

    @media screen and (min-width: ${theme.responsive.large}) {
      font-weight: ${theme.typography.displayFwLg};
    }
  }

  p {
    font-size: ${theme.typography.body.fsSm};
    line-height: ${theme.typography.body.lhSm};
    color: ${theme.colors.darkText};

    @media screen and (min-width: ${theme.responsive.large}) {
      line-height: ${theme.typography.body.lhLg};
    }

    + p {
      margin-top: ${theme.typography.body.lhSm};

      @media screen and (min-width: ${theme.responsive.large}) {
        margin-top: ${theme.typography.body.lhLg};
      }
    }
  }

  a,
  a:hover,
  a:visited {
    color: inherit;
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  hr {
    height: 1px;
    background-color: ${theme.colors.lightBorder};
    border: none;
    margin: 15px 0;

    @media screen and (min-width: ${theme.responsive.large}) {
      margin: 20px 0;
    }
  }

  form {
    legend {
      margin-top: ${theme.typography.sectionTitleSecondary.mtSm};
      margin-bottom: ${theme.typography.sectionTitleSecondary.mbSm};
      font-size: ${theme.typography.sectionTitleSecondary.fsSm};
      line-height: ${theme.typography.sectionTitleSecondary.lhSm};
      font-weight: ${theme.typography.sectionTitleSecondary.fw};

      &:first-child {
        margin-top: 0;
      }

      @media screen and (min-width: ${theme.responsive.large}) {
        margin-top: ${theme.typography.sectionTitleSecondary.mtLg};
        margin-bottom: ${theme.typography.sectionTitleSecondary.mbLg};
        font-size: ${theme.typography.sectionTitleSecondary.fsLg};
        line-height: ${theme.typography.sectionTitleSecondary.lhLg};
        font-weight: ${theme.typography.sectionTitleSecondary.fw};
      }
    }
  }

  button,
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;

    &:focus {
      outline: none;
    }

    &:invalid {
      box-shadow: none;
    }
  }

  .btn {
    display: inline-block;
    min-width: ${theme.button.minWSm};
    margin-left: auto;
    margin-right: auto;
    padding: ${theme.button.pSm};
    font-size: ${theme.button.fsSm};
    font-weight: ${theme.button.fw};
    line-height: ${theme.button.lhSm};
    letter-spacing: ${theme.button.lsSm};
    text-transform: uppercase;
    text-align: center;
    border: 1px solid ${theme.colors.buttonBorder};
  }

  .btn--submit {
    min-width: ${theme.buttonSubmit.minWSm};
    min-height: ${theme.input.minHSm};
    padding: ${theme.buttonSubmit.pSm};
    background-color: ${theme.colors.darkBg};
    font-size: ${theme.button.fsSm};
    font-weight: ${theme.button.fw};
    line-height: ${theme.button.lhSm};
    letter-spacing: ${theme.button.lsSm};
    text-transform: uppercase;
    text-align: center;
    color: white !important; // for <a> elements
    text-decoration: none; // for <a> elements
  }

  .btn--icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button[type="submit"] {
    cursor: pointer;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="submit"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  select,
  textarea {
    min-height: ${theme.input.minHSm};
    padding: 0 ${theme.input.pSm};
    font-size: ${theme.input.fsSm};
    border: none;
    border-radius: 0;
    appearance: none;
    color: ${theme.colors.darkText};
    background-color: white;

    &::placeholder {
      color: ${theme.colors.placeholderText};
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="submit"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    line-height: ${theme.input.lhSm};
  }

  footer {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="submit"],
    input[type="search"],
    input[type="tel"],
    input[type="url"] {
      /* background-color: ${theme.colors.nearlyWhite}; */
      border: 1px solid ${theme.colors.lightBorder};
    }
  }

  textarea {
    min-height: 260px;
    padding: ${theme.input.pSm};
    resize: none;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      display: block;
      padding-left: 33px; // pseudo el width plus gap
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 12.5px);
        left: 0;
        display: inline-block;
        width: 25px; // Testing
        height: 25px; // Testing
        vertical-align: text-top;
        border-radius: 50%;
        transition: background 120ms ease-in, border 60ms ease-in 60ms;
      }

      &::before {
        margin-right: 10px; // Testing
        color: ${theme.colors.buttonBorder};
        border: 1px solid ${theme.colors.buttonBorder};
        border-radius: 50%;
        background: transparent url('data:image/svg+xml;utf8,<svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="${theme.colors.buttonBorder.replace(
          '#',
          '%23'
        )}" fill-rule="nonzero" points="12.367731 0.464463497 13.4178751 1.5355365 5.25555335 9.53836539 0.472496548 4.80589218 1.52750345 3.73960871 5.26034344 7.43297176"></polygon></svg>') center center no-repeat;
      }
    }

    &:hover + label {
      &::before {
        border: none;
        color: white;
        background: ${rgba(
          theme.colors.roseGold,
          0.5
        )} url('data:image/svg+xml;utf8,<svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="${'white'}" fill-rule="nonzero" points="12.367731 0.464463497 13.4178751 1.5355365 5.25555335 9.53836539 0.472496548 4.80589218 1.52750345 3.73960871 5.26034344 7.43297176"></polygon></svg>') center center no-repeat;
      }
    }

    &:checked + label {
      &::before {
        background: ${
          theme.colors.roseGold
        } url('data:image/svg+xml;utf8,<svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="${'white'}" fill-rule="nonzero" points="12.367731 0.464463497 13.4178751 1.5355365 5.25555335 9.53836539 0.472496548 4.80589218 1.52750345 3.73960871 5.26034344 7.43297176"></polygon></svg>') center center no-repeat;
        border: none;
      }
    }
  }

  .select-wrapper {
    position: relative;

    &::after {
      content: '';
      pointer-events: none;
      display: inline-block;
      position: absolute;
      top: calc(50% - 2.5px);
      right: 15px;
      width: 11px;
      height: 5px;
      transform: rotate(180deg);
      background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon id="Path" fill="${theme.colors.darkText.replace(
        '#',
        '%23'
      )}" fill-rule="nonzero" points="1.01615575 7.56237386 0 6.46153846 7 0 14 6.46153846 12.9838442 7.56237386 7 2.03882533"></polygon></svg>') center center no-repeat;
    }
  }

  /* select {
    color: ${theme.colors.placeholderText};

    option {
      color: ${theme.colors.darkText};
    }

    option:first-child {
      color: ${theme.colors.placeholderText};
    }
  } */

  blockquote {
    position: relative;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    font-size: ${theme.typography.blockquote.fsSm};
    line-height: ${theme.typography.blockquote.lhSm};
    font-style: italic;
    font-family: ${theme.typography.displayFamily};
    font-weight: ${theme.typography.displayFwSm};
    color: ${theme.colors.grayText};

    @media screen and (min-width: ${theme.responsive.large}) {
      font-size: ${theme.typography.blockquote.fsLg};
      line-height: ${theme.typography.blockquote.lhLg};
      font-weight: ${theme.typography.displayFwLg};
    }
  }

  // Prevent page scrolling while menu is open (under large screens)
  body.menu-active {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (min-width: ${theme.responsive.large}) {
      position: initial;
      overflow: initial;
    }
  }

  // HELPERS
  .show-lg-up {
    @media screen and (max-width: ${theme.responsive.underLarge}) {
      display: none !important;
    }
  }

  .hide-lg-up {
    @media screen and (min-width: ${theme.responsive.large}) {
      display: none !important;
    }
  }

  .flex {
    display: flex
  }

  .inline-block {
    display: inline-block;
  }

  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .section {
    margin: ${theme.spacing.section.topSm} auto
    ${theme.spacing.section.bottomSm};
    width: 100%;
    flex-grow: 1;

    @media screen and(min-width: ${theme.responsive.large}) {
      margin-top: ${theme.spacing.section.topLg};
      margin-bottom: ${theme.spacing.section.bottomLg};
    }
  }

  .headline--large {
    margin-top: ${theme.typography.headlineLarge.mtSm};
    margin-bottom: ${theme.typography.headlineLarge.mbSm};
    font-size: ${theme.typography.headlineLarge.fsSm};
    line-height: ${theme.typography.headlineLarge.lhSm};

    &:first-child {
      margin-top: 0;
    }

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlineLarge.mtLg};
      margin-bottom: ${theme.typography.headlineLarge.mbLg};
      font-size: ${theme.typography.headlineLarge.fsLg};
      line-height: ${theme.typography.headlineLarge.lhLg};
    }
  }

  .headline--medium {
    margin-top: ${theme.typography.headlineMedium.mtSm};
    margin-bottom: ${theme.typography.headlineMedium.mbSm};
    font-size: ${theme.typography.headlineMedium.fsSm};
    line-height: ${theme.typography.headlineMedium.lhSm};

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlineMedium.mtLg};
      margin-bottom: ${theme.typography.headlineMedium.mbLg};
      font-size: ${theme.typography.headlineMedium.fsLg};
      line-height: ${theme.typography.headlineMedium.lhLg};
    }
  }

  .headline--small {
    margin-top: ${theme.typography.headlineSmall.mtSm};
    margin-bottom: ${theme.typography.headlineSmall.mbSm};
    font-size: ${theme.typography.headlineSmall.fsSm};
    line-height: ${theme.typography.headlineSmall.lhSm};

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlineSmall.mtLg};
      margin-bottom: ${theme.typography.headlineSmall.mbLg};
      font-size: ${theme.typography.headlineSmall.fsLg};
      line-height: ${theme.typography.headlineSmall.lhLg};
    }
  }

  .headline--hero {
    font-size: ${theme.typography.headlineHero.fsSm};
    line-height: ${theme.typography.headlineHero.lhSm};
    letter-spacing: ${theme.typography.headlineHero.lsSm};
    text-transform: uppercase;
  }

  .headline--intro {
    color: ${theme.colors.grayTextAlt}
    margin-bottom: ${theme.typography.headlineIntro.mbSm};
  }

  .section-title {
    margin-bottom: ${theme.typography.sectionTitle.mbSm};
    font-family: ${theme.typography.family};
    font-size: ${theme.typography.sectionTitle.fsSm};
    line-height: ${theme.typography.sectionTitle.lhSm};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: ${theme.typography.sectionTitle.lsSm};
    font-weight: ${theme.typography.sectionTitle.fw};

    @media screen and (min-width: ${theme.responsive.large}) {
      margin-bottom: ${theme.typography.sectionTitle.mbLg};
      font-size: ${theme.typography.sectionTitle.fsLg};
      line-height: ${theme.typography.sectionTitle.lhLg};
      letter-spacing: ${theme.typography.sectionTitle.lsLg};
    }
  }

  .section-title--secondary {
    margin-top: ${theme.typography.sectionTitleSecondary.mbSm};
    margin-bottom: ${theme.typography.sectionTitleSecondary.mbSm};
    font-family: ${theme.typography.family};
    font-size: ${theme.typography.sectionTitleSecondary.fsSm};
    line-height: ${theme.typography.sectionTitleSecondary.lhSm};
    font-weight: ${theme.typography.sectionTitleSecondary.fw};

    &:first-child {
      margin-top: 0;
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      margin-bottom: ${theme.typography.sectionTitleSecondary.mbLg};
      font-size: ${theme.typography.sectionTitleSecondary.fsLg};
      line-height: ${theme.typography.sectionTitleSecondary.lhLg};
    }
  }

  .label {
    margin: 0;
    font-size: ${theme.typography.label.fsSm};
    line-height: ${theme.typography.label.lhSm};
    text-transform: uppercase;
    letter-spacing: ${theme.typography.label.lsSm};
    font-weight: ${theme.typography.label.fw};
    color: ${theme.colors.roseGold};

    @media screen and (min-width: ${theme.responsive.large}) {
      font-size: ${theme.typography.label.fsLg};
      line-height: ${theme.typography.label.lhLg};
      letter-spacing: ${theme.typography.label.lsLg};
    }
  }

  .detail {
    margin: 0;
    font-size: ${theme.typography.detail.fsSm};
    line-height: ${theme.typography.detail.lhSm};
    font-weight: ${theme.typography.detail.fw};
    color: ${theme.colors.grayText};
  }

  .post-body {
    > p {
      &:last-of-type {
        margin-bottom: 15px;

        @media screen and (min-width: ${theme.responsive.large}) {
          margin-bottom: 20px;
        }
      }
    }

    hr {
      margin: 30px 0;
    }
  }
`
export default GlobalStyle
