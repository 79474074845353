import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '../images/close-x.svg'

const CloseButton = styled.button`
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  width: ${15 + 12 + 15}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.black};
  color: white;
  transition: height 150ms ease-in-out;

  > div {
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    p {
      color: white;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const NewArtistBanner = () => {
  const [visibility, setVisibility] = useState(false)

  const LOCALSTORAGE_KEY = 'dismissNewArtistBanner'
  const setLocalStorage = () =>
    window.localStorage.setItem(LOCALSTORAGE_KEY, '🤓')

  useEffect(() => {
    function retrieveFromLocalStorage() {
      if (!window.localStorage.getItem(LOCALSTORAGE_KEY)) {
        setVisibility(true)
      }
      if (window.location.pathname.includes('training')) {
        setVisibility(false)
        setLocalStorage()
      }
    }

    retrieveFromLocalStorage()
  }, [])

  return visibility ? (
    <Banner
      onClick={() => {
        setVisibility(false)
        setLocalStorage()
      }}
    >
      <div>
        <p>
          New Artists, <Link to="/training/">get started here</Link>!
        </p>
        <CloseButton>
          <CloseIcon width="12px" />
        </CloseButton>
      </div>
    </Banner>
  ) : null
}

export default NewArtistBanner
