import React from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

const Wrapper = styled.div`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${theme.sizes.maxWidth};
  flex-grow: 1;

  ${({ wrap }) =>
    wrap &&
    `
      padding-left: ${theme.spacing.siteXPadding};
      padding-right: ${theme.spacing.siteXPadding};
    `}

  ${({ wrapMdUp }) =>
    wrapMdUp &&
    `@media screen and (min-width: ${theme.responsive.medium}) {
      padding-left: ${theme.spacing.siteXPadding};
      padding-right: ${theme.spacing.siteXPadding};
    }`}

  ${({ wrapLgUp }) =>
    wrapLgUp &&
    `@media screen and (min-width: ${theme.responsive.large}) {
      padding-left: ${theme.spacing.siteXPadding};
      padding-right: ${theme.spacing.siteXPadding};
    }`}

  ${({ isPost }) =>
    isPost && `max-width: 780px; // 750 + 15 left + 15 right site x padding`}

  ${({ afterStickyNav }) =>
    afterStickyNav &&
    `margin-top: ${theme.inPageNav.heightSm};

    @media screen and (min-width: ${theme.responsive.large}) {
      margin-top: ${theme.inPageNav.heightLg};
    }`}
`

const Container = props => {
  return <Wrapper {...props}>{props.children}</Wrapper>
}

export default Container
