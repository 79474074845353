const theme = {
  colors: {
    nearlyWhite: '#F7F7F8',
    offWhite: '#F6F7F7',
    roseGold: '#C1938F',
    black: '#121315',
    darkText: '#333333',
    placeholderText: '#999999',
    grayText: '#888888',
    grayTextAlt: '#666666',
    lightGray: '#A2A2A2',
    darkBg: '#333333',
    lightBorder: '#E5E5E5',
    buttonBorder: '#D6D6D6',
    mediumBorder: '#666666',
  },
  typography: {
    family:
      '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    displayFamily:
      '"Didonesque", "Didonesque Roman", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    displayFwSm: '400',
    displayFwLg: '400',
    body: {
      fsSm: '16px',
      lhSm: '24px',
      lhLg: '27px',
    },
    excerpt: {
      lhSm: '24px',
      lhLg: '24px',
    },
    headlineHero: {
      fsSm: '33px',
      lhSm: '34px',
      lsSm: '1px',
      fsLg: '95px',
      lhLg: '95px',
    },
    headlineLarge: {
      mtSm: '14px',
      mbSm: '17px',
      fsSm: '28px',
      lhSm: '36px',
      mtLg: '15px',
      mbLg: '20px',
      fsLg: '46px',
      lhLg: '52px',
    },
    headlineMedium: {
      mtSm: '6px',
      mbSm: '15px',
      fsSm: '22px',
      lhSm: '30px',
      mtLg: '6px',
      mbLg: '15px',
      fsLg: '25px',
      lhLg: '32px',
    },
    headlineSmall: {
      mtSm: '0',
      mbSm: '17px',
      fsSm: '18px',
      lhSm: '22px',
      mtLg: '6px',
      mbLg: '15px',
      fsLg: '25px',
      lhLg: '32px',
    },
    headlineIntro: {
      // mtSm: '15px',
      mbSm: '28px',
      // fsSm: '26px',
      // lhSm: '32px',
      // mtLg: '15px',
      // mbLg: '20px',
      // fsLg: '46px',
      // lhLg: '52px',
    },
    sectionTitle: {
      mbSm: '20px',
      fw: '700',
      fsSm: '17px',
      lhSm: '23px',
      lsSm: '2px',
      mbLg: '40px',
      fsLg: '19px',
      lhLg: '22px',
      lsLg: '3px',
    },
    sectionTitleSecondary: {
      mtSm: '30px',
      mbSm: '20px',
      fw: '600',
      fsSm: '16px',
      lhSm: '22px',
      mbLg: '40px',
      fsLg: '22px',
      lhLg: '28px',
    },
    label: {
      fw: '600',
      fsSm: '11px',
      lhSm: '11px',
      lsSm: '1px',
      fsLg: '11px',
      lhLg: '11px',
      lsLg: '1.5px',
    },
    detail: {
      fsSm: '12px',
      lhSm: '12px',
    },
    blockquote: {
      fsSm: '18px',
      lhSm: '29px',
      fsLg: '24px',
      lhLg: '42px',
    },
  },
  sizes: {
    maxWidth: '1170px', // 1140 + 15 padding-left + 15 padding-right
    maxWidthCentered: '650px',
  },
  input: {
    minHSm: '45px',
    pSm: '15px',
    fsSm: '14px',
    lhSm: '15px',
    fsLg: '14px',
    lhLg: '15px',
  },
  button: {
    minWSm: '220px',
    pSm: '14px 15px 13px',
    fsSm: '11px',
    lhSm: '11px',
    lsSm: '1px',
    fw: '700',
  },
  buttonSubmit: {
    minWSm: '220px',
    pSm: '17px 15px 15px',
  },
  siteBanner: {
    heightSm: '55px',
  },
  navMenu: {
    heightSm: '55px',
    heightLg: '95px',
  },
  inPageNav: {
    fsSm: '13px',
    lhSm: '13px',
    heightSm: '43px', // 13px lh + 15px pt + 15px pb
    heightLg: '80px',
    stuckHeightLg: '53px', // 13px lh + 20px pt + 20px pb
  },
  shared: {
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.1)',
  },
  spacing: {
    siteXPadding: '15px',
    section: {
      topSm: '40px',
      bottomSm: '40px',
      topLg: '80px',
      bottomLg: '80px',
    },
  },
  responsive: {
    small: '375px',
    medium: '768px',
    overMedium: '769px',
    large: '1024px',
    underLarge: '1023px',
  },
  missionStatement: `Everything we do and everything we create is based on what we believe
    in. That helping others look beautiful is nice, but helping them believe
    they are beautiful is life changing. Happiness is contagious. And with
    enough courage, creativity, and optimism, you actually can have your
    cake and eat it too!`,
}

export default theme
