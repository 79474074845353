import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import Logo from '../images/logo.svg'

const Nav = styled.nav`
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 3;
  ${props =>
    props.noHeroSection
      ? `border-bottom: 1px solid ${
          props.theme.colors.lightBorder
        }; background-color: white;`
      : null}
  height: ${props => props.theme.navMenu.heightSm};
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    height: ${props => props.theme.navMenu.heightLg};
    /* justify-content: space-between; */
  }

  + div:first-of-type {
    margin-top: ${props =>
      props.noHeroSection
        ? `calc(${props.theme.navMenu.heightSm} - 10px);`
        : null};
  }

  > .brand-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    fill: ${props =>
      props.noHeroSection ? props.theme.colors.darkText : 'white'};
    color: ${props =>
      props.noHeroSection ? props.theme.colors.darkText : 'white'};

    svg {
      width: 94px;
    }

    @media screen and (min-width: ${props => props.theme.responsive.large}) {
      display: none;

      svg {
        width: 155px;
      }
    }
  }
`

const MenuToggle = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: ${props => props.theme.navMenu.heightSm};
  width: ${props => props.theme.navMenu.heightSm};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    width: 17px;
    height: 1px;
    display: block;
    position: relative;
    background-color: ${props =>
      props.noHeroSection ? props.theme.colors.darkText : 'white'};
    transform-origin: 0.5px 0.5px;
    transform: translate3d(0, 0, 0);
    transition: transform 120ms ease, opacity 40ms ease;

    &:first-child {
      transform: translate3d(0, -5px, 0);
    }

    &:last-child {
      transform: translate3d(0, 5px, 0);
    }
  }

  .menu-active & {
    position: fixed;

    span {
      background-color: ${props => props.theme.colors.darkText};

      &:first-child {
        transform: translate3d(5px, -4px, 0) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translate3d(5px, -5px, 0) rotate(45deg);
      }

      &:last-child {
        transform: translate3d(5px, 5.5px, 0) rotate(-45deg);
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    display: none;
  }
`

const MenuLinks = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 73%;
  max-width: 310px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  left: 0;
  top: 0;
  z-index: 4;
  padding: 50px 0 0;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: transform 120ms ease, opacity 40ms ease;

  .menu-active & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: initial;
    transition: transform 120ms ease, opacity 120ms ease;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    position: relative;
    top: 0;
    width: 100%;
    max-width: ${props => props.theme.sizes.maxWidth};
    height: initial;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-shadow: none;
    padding: 0 ${props => props.theme.spacing.siteXPadding};
    transform: none;
    pointer-events: initial;
    opacity: 1;
    transition: none;
    background-color: transparent;

    .brand-link {
      flex-grow: 1;

      a {
        position: absolute;
        width: 240px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }
    }
  }

  li {
    display: flex;

    a {
      display: block;
      width: 100%;
      padding: 15px 25px;
      color: ${props => props.theme.colors.darkText};
      text-decoration: none;
      font-size: 30px;
      line-height: 30px;

      > span {
        position: relative;

        &::after {
          content: none;
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: ${props => props.theme.colors.roseGold};
        }
      }
    }

    a.active-page-link {
      > span {
        &::after {
          content: '';
        }
      }
    }

    &.brand-link {
      display: none;
    }

    @media screen and (min-width: ${props => props.theme.responsive.large}) {
      a {
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        color: ${props =>
          props.noHeroSection ? props.theme.colors.darkText : 'white'};
        padding: 15px 18px;

        &:hover {
          color: ${props =>
            props.noHeroSection ? props.theme.colors.darkText : 'white'};
        }
      }

      &.brand-link {
        display: block;
      }
    }
  }

  .social-link {
    display: none;

    @media screen and (min-width: ${props => props.theme.responsive.large}) {
      display: flex;
    }

    a {
      padding-left: 10px;
      padding-right: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .brand--mobile-nav {
    margin: 20px auto 5px;

    a {
      text-align: center;

      svg {
        width: 128px;
      }
    }

    @media screen and (min-height: 550px) {
      margin: auto auto 10px;
    }
  }
`

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 120ms ease;
  pointer-events: none;

  .menu-active & {
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: initial;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    display: none;
  }
`

const Menu = ({ children, data, ...props }) => {
  const [menuActiveState, setMenuActiveState] = useState(false)

  useEffect(() => {
    menuActiveState
      ? document.querySelector('body').classList.add('menu-active')
      : document.querySelector('body').classList.remove('menu-active')
  })

  return (
    <Nav noHeroSection={props.noHeroSection}>
      <MenuOverlay onClick={() => setMenuActiveState(!menuActiveState)} />
      <MenuToggle
        noHeroSection={props.noHeroSection}
        onClick={() => setMenuActiveState(!menuActiveState)}
      >
        <span />
        <span />
        <span />
      </MenuToggle>
      <Link className="brand-link" to="/">
        <Logo />
      </Link>
      <MenuLinks noHeroSection={props.noHeroSection}>
        <li>
          <Link
            onClick={() => setMenuActiveState(false)}
            to="/news/"
            activeClassName="active-page-link"
            partiallyActive
          >
            <span>News</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMenuActiveState(false)}
            to="/calendar/"
            activeClassName="active-page-link"
            partiallyActive
          >
            <span>Calendar</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMenuActiveState(false)}
            to="/products/"
            activeClassName="active-page-link"
            partiallyActive
          >
            <span>
              Product<span className="hide-lg-up">s</span>
              <span className="show-lg-up"> Stock</span>
            </span>
          </Link>
        </li>
        <li className="brand-link">
          <Link to="/">
            <Logo className="brand-logo" />
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMenuActiveState(false)}
            to="/training/"
            activeClassName="active-page-link"
            partiallyActive
          >
            <span>Training</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMenuActiveState(false)}
            to="/resources/"
            activeClassName="active-page-link"
            partiallyActive
          >
            <span>Resources</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMenuActiveState(false)}
            to="/support/"
            activeClassName="active-page-link"
            partiallyActive
          >
            <span>Support</span>
          </Link>
        </li>
        <li className="social-link">
          <a
            href="https://www.facebook.com/groups/1829586323964956/"
            target="_blank"
          >
            <Facebook width="8px" />
          </a>
        </li>
        <li className="social-link">
          <a href="https://www.instagram.com/maskcarabeauty/" target="_blank">
            <Instagram width="16px" />
          </a>
        </li>
        <li className="brand--mobile-nav hide-lg-up">
          <Link to="/">
            <Logo className="brand-logo" />
          </Link>
        </li>
      </MenuLinks>
    </Nav>
  )
}

export default Menu
